import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

interface Toast {
  id: string;
  text: string;
  type: 'error' | 'success';
}

interface State {
  toasts: Toast[];
}

interface Store extends State {
  errorToast: (toast: string) => void;
  successToast: (toast: string) => void;
  removeToast: (id: string) => void;
}

export const useToastStore = create<Store>((set, get) => ({
  toasts: [],
  errorToast: (text) =>
    set((state) => {
      const id = uuidv4();
      setInterval(() => get().removeToast(id), 5000);
      return { toasts: [...state.toasts, { text, id, type: 'error' }] };
    }),
  successToast: (text) =>
    set((state) => {
      const id = uuidv4();
      setInterval(() => get().removeToast(id), 5000);
      return { toasts: [...state.toasts, { text, id, type: 'success' }] };
    }),
  removeToast: (id) =>
    set((state) => ({ toasts: state.toasts.filter((t) => t.id !== id) })),
}));
