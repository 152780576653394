import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

import Badge from '../elements/Badge';
import SortRuleHeader from '../elements/SortRuleHeader';
import SimpleGrid from '../elements/Table';
import { SortRule } from '../model/SortRule';

import { OrderDto } from './dto/order.dto';

type OrdersGridProps = {
  data: OrderDto[]
  onSortRulesUpdate?: (sortRule: SortRule) => void
  sortRules: SortRule[]
  headerMap?: Record<string, string>;
};

const OrdersGrid = ({
  data,
  onSortRulesUpdate,
  sortRules,
  headerMap,
}: OrdersGridProps) => {
  const navigate = useNavigate();

  const openOrderDetails = (orderId: string) => {
    navigate(`/bestellingen/${orderId}`);
  };

  const updateSortOrder = (sortRule: SortRule) => {
    sortRule.updateSortOrder();

    if (onSortRulesUpdate) {
      onSortRulesUpdate(sortRule);
    }
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Status',
      field: 'state',
      filter: false,
      sortable: false,
      minWidth: 140,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="state"
            updateSortOrder={updateSortOrder}
            headerMap={headerMap}
          />)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return (
          <Badge
            color={stateColorMap[params.value]}
            text={stateTranslationMap[params.value]}
          />
        );
      },
    },
    {
      headerName: 'Prijs',
      field: 'total_inc_vat',
      filter: false,
      minWidth: 140,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="price"
            updateSortOrder={updateSortOrder}
            headerMap={headerMap}
          />)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return params.value === '' ? '--' : `€ ${params.value.toFixed(2)}`;
      }
    },
    {
      headerName: 'Email',
      field: 'user_details.email',
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="email"
            updateSortOrder={updateSortOrder}
            headerMap={headerMap}
          />)
      },
      filter: false,
      sortable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: 'Aangemaakt op',
      field: 'created_at',
      filter: false,
      sortable: false,
      minWidth: 220,
      flex: 1,
      headerComponent: () => {
        return (
          <SortRuleHeader
            sortRules={sortRules}
            column="created_at"
            updateSortOrder={updateSortOrder}
            headerMap={headerMap}
          />)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
  ]

  const stateColorMap: Record<string, string> = {
    BLOCKED: '#ffbbbb',
    ACTIVE: '#b1e9c8',
    TO_ACTIVATE: '#ffad5d',
    PENDING: '#ffad5d', // Example color for PENDING
    PAYMENT_REQUIRED: '#ffbbbb', // Example color for PAYMENT_REQUIRED
    CONFIRMED: '#5bc0de', // Example color for CONFIRMED
    SHIPPED: '#5cb85c', // Example color for SHIPPED
    DELIVERED: '#0275d8', // Example color for DELIVERED
    CANCELED: '#ffbbbb', // Example color for CANCELED
    UNKNOWN: '#eee', // Example color for UNKNOWN
    PAID: '#b1e9c8', // Example color for PAID
    OPEN: '#ffad5d', // Example color for OPEN (same as PENDING)
    PAYMENT_FAILED: '#ffbbbb', // Example color for PAYMENT_FAILED
    EXPIRED: '#6c757d', // Example color for EXPIRED
    SETTLED: '#5bc0de', // Example color for SETTLED
    CHARGEBACK: '#ffbbbb', // Example color for CHARGEBACK
    REFUNDED: '#0275d8', // Example color for REFUNDED
    REFUND_PROCESSING: '#f0ad4e', // Example color for REFUND_PROCESSING
    PARTIALLY_REFUNDED: '#5bc0de', // Example color for PARTIALLY_REFUNDED
    AUTHORISED: '#b1e9c8', // Example color for AUTHORISED
  };

  const stateTranslationMap: Record<string, string> = {
    PENDING: 'In afwachting',
    PAYMENT_REQUIRED: 'Betaling vereist',
    CONFIRMED: 'Bevestigd',
    SHIPPED: 'Verzonden',
    DELIVERED: 'Geleverd',
    CANCELED: 'Geannuleerd',
    UNKNOWN: 'Onbekend',
    PAID: 'Betaald',
    OPEN: 'In afwachting',
    PAYMENT_FAILED: 'Betaling mislukt',
    EXPIRED: 'Verlopen',
    BLOCKED: 'Geblokkeerd',
    SETTLED: 'Settled',
    CHARGEBACK: 'Chargeback',
    REFUNDED: 'Terugbetaald',
    REFUND_PROCESSING: 'Refund processing',
    PARTIALLY_REFUNDED: 'Gedeeltelijk terugbetaald',
    AUTHORISED: 'Geautoriseerd',
  };


  return (
    <SimpleGrid
      columnDefs={columnDefs}
      rowData={data}
      onRowClick={(params) => openOrderDetails(params.data.id)}
    />
  )
}

export default OrdersGrid;
