import { jwtDecode } from 'jwt-decode';
import { z } from 'zod';

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

const refreshToken = () => {
  const jwtToken = getCookie('mortex-jwt-refresh');

  return fetch('/api/v1/auth/refresh-token', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    body: undefined,
  });
};

export const doFetchBlob = async (
  url: string
): Promise<{ data: Blob; status: number }> => {
  const jwtToken = getCookie('mortex-jwt');

  const response = await fetch(url, {
    method: 'GET',
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      ...(jwtToken && { Authorization: `Bearer ${jwtToken}` }),
    },
  });

  const parsedData = await response.blob();

  // refresh token and try again
  if (response.status === 401) {
    await refreshToken();
  }

  return {
    data: parsedData,
    status: response.status,
  };
};

export const doFetch = async (
  url: string,
  method: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  retries: number = 0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ status: number; data: any }> => {
  const jwtToken = getCookie('mortex-jwt');

  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      ...(jwtToken && { Authorization: `Bearer ${jwtToken}` }),
    },
    body: method !== 'GET' ? JSON.stringify(data) : undefined, // body data type must match "Content-Type" header
  });

  const parsedData = await response.json();

  // refresh token and try again
  if (response.status === 401) {
    if (parsedData.refresh && retries < 3) {
      await refreshToken();
      return doFetch(url, method, data, retries + 1);
    }

    window.location.href = `${getSsoDomain()}/login?redirect_url=${window.location.href}`;
  }

  return {
    status: response.status,
    data: parsedData,
  };
};

export const uploadFileFetch = async (
  url: string,
  method: string,
  files: File[]
) => {
  const jwtToken = getCookie('mortex-jwt');

  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const response = await fetch(url, {
    method: method,
    mode: 'same-origin',
    cache: 'default',
    credentials: 'same-origin',
    headers: {
      ...(jwtToken && { Authorization: `Bearer ${jwtToken}` }),
    },
    body: formData, // FormData object for file upload
  });

  try {
    const data = await response.json();
    return {
      status: response.status,
      data,
    };
  } catch {
    return {
      status: response.status,
      data: null,
    };
  }
};

export const downloadFile = async (url: string, fileName: string) => {
  try {
    const { data } = await doFetchBlob(url);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('There was an error downloading the file:', error);
  }
};

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // Ensure the result is a string before resolving
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('Failed to read file as base64 string');
      }
    };
    reader.onerror = () => {
      // Handle errors such as file not readable
      reader.abort();
      reject('Error reading file');
    };
    reader.readAsDataURL(file);
  });
}

// get the account details from the jwt token
export const getAccountDetails = async (
  retries = 0
): Promise<undefined | { account_id: string; email: string; name: string }> => {
  const token = getCookie('mortex-jwt');
  if (!token && retries < 3) {
    await refreshToken();
    return getAccountDetails(retries + 1);
  }

  if (!token) {
    return;
  }

  try {
    const decoded = jwtDecode(token);
    return z
      .object({
        account_id: z.string(),
        email: z.string().email(),
        name: z.string(),
      })
      .parse(decoded);
  } catch (e) {
    return;
  }
};

export const getSsoDomain = () => {
  return window.location.href.replace('admin', 'sso');
};
