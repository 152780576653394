import { z } from 'zod';

export enum DiscountCodeType {
  REGULAR = 'REGULAR',
}

export enum DiscountCodeStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
}

export const discountCodeSchema = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DiscountCodeType),
  code: z.string(),
  status: z.nativeEnum(DiscountCodeStatus),
  total: z.number(),
  used: z.number(),
  created_at: z.string().datetime({ offset: true }),
  expiring_at: z.string().datetime({ offset: true }),
  updated_at: z.string().datetime({ offset: true }).nullable(),
});

export type DiscountCodeDto = z.infer<typeof discountCodeSchema>;
