
import { Cog, File, Info, Lock, RefreshCw, Save, Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useModalStore } from '../../stores/modalStore';
import { useToastStore } from '../../stores/toastStore';
import ApiError from '../ApiError';
import { MODALS, stateTranslationMap } from '../constants';
import { Button, Placeholder } from '../elements';
import InputError from '../elements/InputError';
import { Loading } from '../partials';

import { CustomerState } from './dto/customer.dto';
import { useCustomerById, useOrdersFromCustomerById, useResetPassword, useUpdateCustomer, useUpdateCustomerForm } from './hooks';
import DeleteCustomerModal from './modal/DeleteCustomerModal';

type CustomersDetailsContainerProps = {
  customerId: string;
};

type CustomerOrdersProps = {
  customerId: string;
}

const CustomerOrders = ({ customerId }: CustomerOrdersProps) => {
  const navigate = useNavigate();
  const { data, isLoading } = useOrdersFromCustomerById(customerId);

  const goToOrderDetails = (orderId: string) => {
    navigate(`/bestellingen/${orderId}`);
  };

  if (isLoading) {
    return <Loading />;
  }

  const rowData = data ?? [];

  if (rowData.length === 0) {
    return (
      <Placeholder
        className="bg-primary mb-0"
        text="Geen bestellingen gevonden"
      />
    )
  }

  return (
    <ul>
      {
        rowData.map((order) => (
          <li
            className="cursor-pointer bg-primary p-4 rounded-lg mb-4 grid grid-cols-3 gap-4"
            onClick={() => goToOrderDetails(order.id)}
          >
            <div className="flex flex-col gap-2">
              <label className="font-bold text-sm">Status</label>
              {stateTranslationMap[order.state]}
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold text-sm">Prijs</label>
              <span>
                {order.total_inc_vat.toFixed(2)}
              </span>
            </div>


            <div className="flex flex-col gap-2">
              <label className="font-bold text-sm">Besteld op</label>
              <span>
                {
                  new Date(order.created_at).toLocaleString()
                }
              </span>
            </div>
          </li>
        ))
      }
    </ul>
  )
}

const CustomersDetailsContainer = ({
  customerId,
}: CustomersDetailsContainerProps) => {
  const modalStore = useModalStore();
  const { isLoading, data } = useCustomerById(customerId);
  const { reset, register, handleSubmit, getValues, watch, formState: { errors } } = useUpdateCustomerForm(data);
  const { updateCustomer } = useUpdateCustomer();
  const { mutateAsync: sendResetPassword } = useResetPassword();
  const { errorToast, successToast } = useToastStore();
  const [error, setError] = useState<string | null>(null);

  watch('name');

  useEffect(() => {
    if (data !== undefined) {
      reset(data);
    }
  }, [reset, data]);

  const displayDeactivateCustomer = () => {
    modalStore.openModal(MODALS.DELETE_CUSTOMER);
  };

  const resetPassword = async () => {
    try {
      if (data === undefined) {
        throw new Error('Customer data is not available');
      }

      await sendResetPassword(data.email);
      successToast('Email om wachtwoord te veranderen is verstuurd');
    } catch (err) {
      if (err instanceof ApiError && err.status === 409) {
        errorToast('Het account is geblokkeerd, verander de status alvorens je een reset wachtwoord-email stuurt');
        return
      }

      errorToast('Er is een fout opgetreden bij het versturen van de reset wachtwoord-email');
    }
  };

  const performUpdateCustomer = async () => {
    try {
      await updateCustomer(getValues());
      successToast('Klant is succesvol bijgewerkt');
    } catch (err) {
      if (err instanceof ApiError && err.status === 409) {
        setError('Er is al een klant met dit emailadres');
      }

      errorToast('Er is een fout opgetreden bij het bijwerken van de klant');
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  if (data === undefined) {
    return <div>Er is iets misgegaan, probeer het later opnieuw</div>;
  }

  return (
    <>
      <DeleteCustomerModal customerId={data.id} />
      <div className="flex gap-4 items-start justify-between flex-col sm:items-center sm:flex-row mb-8">
        <h1 className="font-bold">Klant - {data.name}</h1>
      </div>

      <div className="grid mb-4 gap-4 grid-cols-1 md:grid-cols-2">
        <div className="bg-lighter p-4 rounded-lg">
          <div className="flex justify-start items-center mb-8 gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Klanten gegevens
            </h2>
          </div>

          {
            error !== null && (
              <div className="text-error rounded-lg mb-4">{error}</div>
            )
          }

          <form onSubmit={handleSubmit(performUpdateCustomer)}>
            <div className="flex flex-col mb-4">
              <label htmlFor="name" className="mb-1 font-semibold">
                Name
              </label>
              <input
                {...register('name')}
                placeholder="John Doe"
                className="p-4 bg-primary rounded-lg"
              />

              {
                errors.name && (
                  <InputError text={errors.name.message} />
                )
              }
            </div>
            <div className="flex flex-col mb-4">
              <label htmlFor="email" className="mb-1 font-semibold">
                Email
              </label>
              <input
                {...register('email')}
                placeholder="customer@diy-atelier.be"
                className="p-4 bg-primary rounded-lg"
              />

              {
                errors.email && (
                  <InputError text={errors.email.message} />
                )
              }
            </div>
            <div className="flex flex-col mb-4">
              <label htmlFor="email" className="mb-1 font-semibold">
                Status
              </label>
              <select
                {...register('state')}
                className="p-4 bg-primary rounded-lg"
              >
                <option value="INACTIVE">Inactief</option>
                <option value="ACTIVE">Actief</option>
                <option value="TO_ACTIVATE">Te activeren</option>
                <option value="BLOCKED">Geblokkeerd</option>
              </select>
            </div>
            <Button
              type="submit"
              text="Bewaar wijzigingen"
              icon={<Save />}
              variant="primary"
              style={{ justifyContent: 'center' }}
              fullWidth
            />
          </form>
        </div>

        <div className="bg-lighter p-4 rounded-lg">
          <div className="flex justify-start items-center mb-8 gap-4">
            <Cog />
            <h2 className="text-xl font-bold">Snelle acties</h2>
          </div>

          <div className="flex flex-col gap-4">
            <Button
              text="Reset wachtwoord"
              onClick={resetPassword}
              fullWidth
              style={{ justifyContent: 'center' }}
              variant="primary"
              icon={<Lock />}
            />

            {
              data.state === CustomerState.ACTIVE && (
                <Button
                  onClick={displayDeactivateCustomer}
                  variant="delete"
                  fullWidth
                  style={{ justifyContent: 'center' }}
                  text="Deactiveer klant"
                  icon={<Trash />}
                />
              )
            }

            {
              (data.state === CustomerState.TO_ACTIVATE || data.state === CustomerState.INACTIVE) && (
                <Button
                  onClick={displayDeactivateCustomer}
                  variant="primary"
                  fullWidth
                  style={{ justifyContent: 'center' }}
                  text="Activeer klant"
                  icon={<RefreshCw />}
                />
              )
            }

          </div>
        </div>

      </div>


      <div className="bg-lighter p-4 rounded-lg">
        <div className="flex justify-start items-center mb-8 gap-4">
          <File />
          <h2 className="text-xl font-bold">Bestellingen</h2>
        </div>

        <CustomerOrders customerId={customerId} />
      </div>

    </>
  );
};

const CustomerDetailsPage = () => {
  const { customerId } = useParams();

  return (
    <main
      className="p-4 md:p-16"
    >
      {customerId && <CustomersDetailsContainer customerId={customerId} />}
    </main>
  );
};

export default CustomerDetailsPage;
