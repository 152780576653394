import { z } from 'zod';

import { discountCodeSchema } from './discount-code.dto';

export const addDiscountCodeSchema = discountCodeSchema
  .omit({
    id: true,
    code: true,
    created_at: true,
    updated_at: true,
    used: true,
  })
  .merge(
    z.object({
      expiring_at: z.string(),
    })
  );

export type AddDiscountCodeDto = z.infer<typeof addDiscountCodeSchema>;
