import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

import Badge from '../elements/Badge';
import SimpleGrid from '../elements/Table';

import { DiscountCodeDto } from './dto/discount-code.dto';

type DiscountCodesGridProps = {
  data: DiscountCodeDto[]
};

const DiscountCodesGrid = ({ data }: DiscountCodesGridProps) => {
  const navigate = useNavigate();

  const stateColorMap: Record<string, string> = {
    DELETED: '#ffbbbb',
    ACTIVE: '#b1e9c8',
    EXPIRED: '#ffad5d',
  };

  const stateTranslationMap: Record<string, string> = {
    DELETED: 'Geblokkeerd',
    ACTIVE: 'Actief',
    TO_ACTIVATE: 'Te activeren',
    INACTIVE: 'Verwijderd',
  };

  const goToDiscountCodeDetails = (discountCodeId: string) => {
    navigate(`/kortingscodes/${discountCodeId}`);
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Status',
      field: 'status',
      filter: false,
      sortable: false,
      minWidth: 140,
      flex: 1,
      headerComponent: () => {
        return (
          <div
            className="flex items-center gap-4"
          >
            <span>Status</span>
          </div>
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return (
          <Badge
            color={stateColorMap[params.value]}
            text={stateTranslationMap[params.value]}
          />
        );
      },
    },
    {
      headerName: 'Totaal waarde',
      field: 'total',
      filter: false,
      minWidth: 200,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <div
            className="flex items-center gap-4"
          >
            <span>Totaal waarde</span>
          </div>
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return (
          <div>
            €{parseFloat(params.value).toFixed(2)}
          </div>
        )
      },
    },
    {
      headerName: 'Gebruikte waarde',
      field: 'used',
      filter: false,
      minWidth: 200,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <div
            className="flex items-center gap-4"
          >
            <span>Gebruikte waarde</span>
          </div>
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return (
          <div>
            €{parseFloat(params.value).toFixed(2)}
          </div>
        )
      },
    },
    {
      headerName: 'Aangemaakt op',
      field: 'created_at',
      filter: false,
      minWidth: 200,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <div
            className="flex items-center gap-4"
          >
            <span>Aangemaakt op</span>
          </div>
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      headerName: 'Vervalt op',
      field: 'expiring_at',
      filter: false,
      minWidth: 200,
      sortable: false,
      flex: 1,
      headerComponent: () => {
        return (
          <div
            className="flex items-center gap-4"
          >
            <span>Vervalt op</span>
          </div>
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellRenderer: (params: any) => {
        return new Date(params.value).toLocaleDateString();
      },
    }
  ];

  return (
    <SimpleGrid
      columnDefs={columnDefs}
      rowData={data}
      onRowClick={(params) => goToDiscountCodeDetails(params.data.id)}
    />
  )
}

export default DiscountCodesGrid;
