import { Save, Trash } from 'lucide-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useModalStore } from '../../stores/modalStore';
import { useToastStore } from '../../stores/toastStore';
import { MODALS } from '../constants';
import { Button } from '../elements';
import { Loading } from '../partials';

import ProductContainer from './_productContainer';
import { useProductById, useProductForm, useUpdateProduct } from './hooks';
import DeleteProductModal from './modal/DeleteProductModal';

type ProductDetailContainerProps = {
  productId: string;
};

const ProductDetailContainer = ({ productId }: ProductDetailContainerProps) => {
  const {
    data: product,
    isLoading,
    isError,
    error,
  } = useProductById(productId);

  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();

  const { updateProduct } = useUpdateProduct();
  const productForm = useProductForm(product);
  const { reset } = productForm;

  useEffect(() => {
    if (!isLoading && product !== undefined) {
      reset(product);
    }
  }, [productId, isLoading, reset, product]);

  const handleUpdateProduct = async () => {
    try {
      const values = productForm.getValues();
      if (values.variants.length === 0) {
        errorToast('Voeg op zijn minst 1 variant toe');
        return;
      }

      await updateProduct(values);
      successToast('Product opgeslagen');
    } catch (err) {
      errorToast('Er is iets misgegaan bij het opslaan van het product');
      console.error(err);
    }
  };

  const showDeleteWarning = () => {
    modalStore.openModal(MODALS.DELETE_TUTORIAL);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  if (product === undefined) {
    return <div>Missing data</div>;
  }

  const { getValues } = productForm;
  const { name, category_id } = getValues();

  return (
    <>
      <DeleteProductModal
        productId={productId}
        categoryId={category_id ?? ''}
      />

      <div className="flex-col items-start flex gap-4 lg:flex-row lg:items-center mb-16 lg:justify-between">
        <h1 className="font-bold mb-0">{name === '' ? '--' : name}</h1>
        <div className="flex gap-4 w-full sm:w-max">
          <Button
            onClick={handleUpdateProduct}
            variant="primary"
            text="sla product op"
            icon={<Save />}
          />
          <Button
            onClick={showDeleteWarning}
            variant="delete"
            icon={<Trash />}
          />
        </div>
      </div>

      <ProductContainer product={product} productForm={productForm} editing={true} />
    </>
  );
};

const ProductDetailPage = () => {
  const { productId } = useParams();

  return (
    <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
      {productId && <ProductDetailContainer productId={productId} />}
    </main>
  );
};

export default ProductDetailPage;
