import { format } from 'date-fns';
import { Plus, X } from 'lucide-react';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import { Button } from '../../elements';
import InputError from '../../elements/InputError';
import Modal from '../../elements/Modal';
import { DiscountCodeStatus, DiscountCodeType } from '../dto/discount-code.dto';
import { useAddDiscountCodeForm, useAddDiscountCode } from '../hooks';

function oneYearFromToday(): string {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  return format(now, 'yyyy-MM-dd');
}

const AddDiscountCodeModal = () => {
  const modalStore = useModalStore();
  const { isPending, mutateAsync: addDiscountCode } = useAddDiscountCode();
  const { errorToast, successToast } = useToastStore();

  const { handleSubmit, formState: { errors }, getValues, reset, register } = useAddDiscountCodeForm({
    type: DiscountCodeType.REGULAR,
    status: DiscountCodeStatus.ACTIVE,
    total: 0,
    expiring_at: oneYearFromToday()
  });

  const close = () => {
    modalStore.closeModal(MODALS.ADD_DISCOUNT_CODE);
  };

  const doAddDiscountCode = async () => {
    try {
      await addDiscountCode(getValues());
      reset();
      close();
      successToast('Kortingscode toegevoegd');
    } catch (err) {
      errorToast('Er is iets misgegaan bij het toevoegen van de kortingscode');
      close();
    }
  };

  return (
    <Modal id={MODALS.ADD_DISCOUNT_CODE}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Voeg kortingscode toe</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="p-8 pb-0 w-full">
          <form onSubmit={handleSubmit(doAddDiscountCode)}>
            <div className="w-full mb-4">
              <label className="mb-2 block font-bold">Totaal bedrag in €</label>
              <input
                className="w-full p-4 text-text bg-primary rounded-lg"
                type="text"
                placeholder="100"
                {...register('total', {
                  valueAsNumber: true
                })}
              />

              {errors?.total !== undefined && (
                <InputError text={errors.total.message} />
              )}
            </div>

            <div className="w-full mb-4">
              <label className="mb-2 block font-bold">Verloopt op</label>
              <input
                className="w-full p-4 text-text bg-primary rounded-lg"
                type="date"
                {...register('expiring_at')}
              />

              {errors?.expiring_at !== undefined && (
                <InputError text={errors.expiring_at.message} />
              )}
            </div>

            <Button
              type="submit"
              disabled={isPending}
              text="Voeg kortingscode toe"
              icon={<Plus />}
              fullWidth
              style={{ justifyContent: 'center' }}
              variant="primary"
              className="mb-4"
            />

            <Button
              onClick={close}
              disabled={isPending}
              fullWidth
              style={{ justifyContent: 'center' }}
              text="Annuleren"
              variant="tertiary"
            />
          </form>
        </div>

      </>
    </Modal>
  );
};

export default AddDiscountCodeModal;
