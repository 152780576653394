import { X } from 'lucide-react';

import { useModalStore } from '../../../stores/modalStore';
import { useToastStore } from '../../../stores/toastStore';
import { MODALS } from '../../constants';
import Modal from '../../elements/Modal';
import { Variant } from '../dto/product.dto';

type RemoveVariantModalProps = {
  removeVariant: (variant: Variant) => void
  variant: Variant | null
};

const RemoveVariantModal = ({ variant, removeVariant }: RemoveVariantModalProps) => {
  const modalStore = useModalStore();
  const { successToast, errorToast } = useToastStore();

  const close = () => {
    modalStore.closeModal(MODALS.REMOVE_VARIANT);
  };

  const doRemoveVariant = async () => {
    try {
      successToast('Variant verwijderd');
      if (variant) {
        removeVariant(variant);
      }
      close();
    } catch (err) {
      errorToast('Er is iets misgegaan bij het verwijderen van de variant');
    }
  }

  return (
    <Modal id={MODALS.REMOVE_VARIANT}>
      <>
        <div className="relative pt-4">
          <h1 className="ml-8 text-text text-2xl font-bold">Verwijderen van variant</h1>
          <X
            className="absolute right-0 top-0 text-text cursor-pointer"
            onClick={close}
          />
        </div>

        <div className="p-8 pb-0 w-full">
          <p>
            Weet je zeker dat je deze variant wilt verwijderen? Dit kan niet ongedaan gemaakt worden.
          </p>

          <button
            type="button"
            onClick={doRemoveVariant}
            className="bg-accent text-primary p-4 w-full rounded-lg mt-4 hover:bg-accent-hover duration-300 transition-all"
          >
            Ja, ik wil dit verwijderen
          </button>

          <button
            className="text-text text-center w-full mt-4 hover:text-accent"
            onClick={close}
          >
            Nee, ik wil dit niet verwijderen
          </button>
        </div>
      </>
    </Modal>
  );
};

export default RemoveVariantModal;
