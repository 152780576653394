'use client';

import * as Toast from '@radix-ui/react-toast';
import { X } from 'lucide-react';

import { useToastStore } from '../../stores/toastStore';

const ToastContainer = () => {
  const { toasts } = useToastStore();

  return (
    <Toast.Provider>
      {
        toasts.map((toast) => (
          <Toast.Root className={`ToastRoot ${toast.type === 'error' ? 'bg-error text-white' : ''}`} key={toast.id}>
            <Toast.Title className={`ToastTitle ${toast.type === 'error' ? 'bg-error text-white' : ''}`}>{toast.text}</Toast.Title>
            <Toast.Close className="ToastClose" asChild>
              <button className="Button small red" aria-label="Close">
                <X />
              </button>
            </Toast.Close>
          </Toast.Root>
        ))
      }

      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  )
}

export default ToastContainer;
