import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AdminDetails from './administrators/AdministratorDetails';
import Admins from './administrators/Page';
import AddProduct from './catalogus/AddProductPage';
import Catalogus from './catalogus/OverviewPage';
import ProductDetails from './catalogus/ProductDetail';
import CustomerDetails from './customers/CustomerDetails';
import Customers from './customers/Page';
import Dashboard from './dashboard/Page';
import DiscountCodeDetailsPage from './discount-codes/DiscountCodeDetailsPage';
import DiscountCodesPage from './discount-codes/Page';
import OrderDetailsPage from './orders/OrderDetails';
import Orders from './orders/Page';
import { Header, Loading } from './partials';
import Realisations from './realisations/Page';
import Tutorials from './tutorials/Page';

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/klanten" element={<Customers />} />
          <Route path="/klanten/:customerId" element={<CustomerDetails />} />
          <Route path="/administrators" element={<Admins />} />
          <Route
            path="/administrators/:administratorId"
            element={<AdminDetails />}
          />
          <Route path="/bestellingen" element={<Orders />} />
          <Route path="/bestellingen/:orderId" element={<OrderDetailsPage />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/catalogus" element={<Catalogus />} />
          <Route path="/catalogus/:categoryId" element={<Catalogus />} />
          <Route path="/nieuw-product" element={<AddProduct />} />
          <Route path="/realisaties" element={<Realisations />} />
          <Route path="/kortingscodes" element={<DiscountCodesPage />} />
          <Route path="/kortingscodes/:codeId" element={<DiscountCodeDetailsPage />} />
          <Route
            path="/categorie/:categoryId/nieuw-product"
            element={<AddProduct />}
          />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
