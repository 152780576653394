import { FileImage, LayoutDashboard, List, Menu, Percent, ShieldCheck, ShoppingCart, Users, Video, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { useToastStore } from '../../stores/toastStore';
import { getAccountDetails } from '../utils';

import { useLogout } from './hooks';

type NavItemProps = {
  name: string;
  url: string;
  toggleMenu?: () => void;
  icon: JSX.Element;
};

const NavItem = ({ name, url, toggleMenu, icon: Icon }: NavItemProps) => {
  const location = useLocation();

  const activeMap: Record<string, string[]> = {
    '/dashboard': ['/dashboard'],
    '/klanten': ['/klanten'],
    '/bestellingen': ['/bestellingen'],
    '/tutorials': ['/tutorials'],
    '/catalogus': ['/catalogus', '/categorie', '/product'],
    '/administrators': ['/administrators'],
    '/realisaties': ['/realisaties'],
    '/kortingscodes': ['/kortingscodes'],
  };

  let isActive = activeMap[url]?.some((path) =>
    location.pathname.startsWith(path),
  );

  if (location.pathname === '/' && url === '/dashboard') {
    isActive = true;
  }

  return (
    <Link
      to={url}
      className={`p-2 rounded-lg flex flex-col justify-center items-center gap-2 ${isActive ? 'bg-light-accent text-accent font-bold' : ''}`}
      onClick={toggleMenu}
    >
      <div className="w-[18px]">
        {Icon}
      </div>
      <span className="text-[12px]">
        {name}
      </span>
    </Link>
  );
};

const Header = () => {
  const { errorToast } = useToastStore();
  const { logout } = useLogout();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState<{
    account_id: string;
    email: string;
    name: string | undefined;
  }>();

  useEffect(() => {
    const loadData = async () => {
      const account = await getAccountDetails();
      if (account) {
        setAccountDetails(account);
      }
    };

    loadData();
  }, []);


  const doLogout = async () => {
    try {
      await logout();
      // Get the current hostname
      const currentDomain = window.location.hostname;

      // Replace 'admin' with 'sso' to get the SSO domain
      const ssoDomain = currentDomain.replace('admin', 'sso');

      // Construct the SSO URL
      const ssoUrl = `https://${ssoDomain}`;

      // Redirect to the SSO URL
      window.location.href = ssoUrl;
    } catch (err) {
      errorToast('Er is iets fout gegaan bij het uitloggen.');
    }
  }

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const links = [
    { name: 'Dashboard', url: '/dashboard', icon: <LayoutDashboard /> },
    { name: 'Klanten', url: '/klanten', icon: <Users /> },
    { name: 'Orders', url: '/bestellingen', icon: <List /> },
    { name: 'Tutorials', url: '/tutorials', icon: <Video /> },
    { name: 'Catalogus', url: '/catalogus', icon: <ShoppingCart /> },
    { name: 'Administrators', url: '/administrators', icon: <ShieldCheck /> },
    { name: 'Realisaties', url: '/realisaties', icon: <FileImage /> },
    { name: 'Kortings codes', url: '/kortingscodes', icon: <Percent /> },
  ];

  return (
    <>
      <div
        className="w-full tablet:hidden justify-between items-center p-4 bg-lighter fixed z-100"
        style={{ zIndex: 100 }}
      >
        <img src={logo} alt="Mijnmortex logo" width="50" />

        <button
          className={`top-4 right-4 p-2 z-[100] absolute bg-light-accent rounded-lg`}
          onClick={toggleMenu}
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      <aside className={`
          overflow-y-auto
          top-8 left-0 h-full bg-lighter z-50 transition-transform transform
          tablet:fixed tablet:translate-x-0 p-8 flex flex-col items-center
          tablet:min-h-[100px] w-[200px] tablet:w-[200px] fixed
          tablet:top-0
          ${isMenuOpen ? 'translate-x-0 w-full' : '-translate-x-full'}
        `}>
        <img src={logo} alt="Mijnmortex logo" width="50" className="hidden tablet:block" />

        <div className="flex flex-col mt-8">
          <span>
            Hi, {accountDetails?.name}!
          </span>

          <a href="#" className="text-[#716658] cursor-pointer" onClick={doLogout}>
            Uitloggen
          </a>
        </div>

        <nav className="tablet:block mt-[5rem]">
          <ul className="flex gap-4 flex-col">
            {links.map((link) => (
              <NavItem
                icon={link.icon}
                name={link.name}
                url={link.url}
                key={link.url}
                toggleMenu={toggleMenu}
              />
            ))}
          </ul>
        </nav>
      </aside>

      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 tablet:hidden"
          onClick={toggleMenu}
        />
      )}
    </>
  );
};

export default Header;

