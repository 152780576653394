import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { QUERY_KEYS } from '../constants';
import { doFetch } from '../utils';

import {
  AddDiscountCodeDto,
  addDiscountCodeSchema,
} from './dto/add-discount-code.dto';
import { discountCodeSchema } from './dto/discount-code.dto';
import { getDiscountCodesSchema } from './dto/get-discount-codes.dto';
import { UpdateDiscountCodeDto } from './dto/update-discount-code.dto';

const URLS = {
  GET_DISCOUNT_CODES: '/api/v1/discount-codes',
  ADD_DISCOUNT_CODE: '/api/v1/discount-codes',
  UPDATE_DISCOUNT_CODE: (discountCodeId: string) =>
    `/api/v1/discount-codes/${discountCodeId}`,
  DELETE_DISCOUNT_CODE: (discountCodeId: string) =>
    `/api/v1/discount-codes/${discountCodeId}`,
};

export const useAddDiscountCodeForm = (discountCode?: AddDiscountCodeDto) => {
  return useForm<AddDiscountCodeDto>({
    defaultValues: discountCode,
    resolver: zodResolver(addDiscountCodeSchema),
  });
};

export const useUpdateDiscountCodeForm = (
  discountCode?: UpdateDiscountCodeDto
) => {
  return useForm<UpdateDiscountCodeDto>({
    defaultValues: discountCode,
    resolver: zodResolver(addDiscountCodeSchema),
  });
};

export const useDiscountCodes = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.discount_codes],
    queryFn: async () => {
      try {
        const { status, data } = await doFetch(URLS.GET_DISCOUNT_CODES, 'GET');

        if (status !== 200) {
          throw new Error();
        }

        return getDiscountCodesSchema.parse(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });
};

export const useAddDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (discountCode: AddDiscountCodeDto) => {
      const res = await doFetch(URLS.ADD_DISCOUNT_CODE, 'POST', discountCode);

      if (res.status !== 201) {
        throw new Error();
      }

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.discount_codes],
      });
    },
  });
};

export const useDiscountCodeById = (discountCodeId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.discount_codes, discountCodeId],
    queryFn: async () => {
      try {
        const { status, data } = await doFetch(
          URLS.DELETE_DISCOUNT_CODE(discountCodeId),
          'GET'
        );

        if (status !== 200) {
          throw new Error();
        }

        return discountCodeSchema.parse(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });
};

export const useDeleteDiscountCode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (discountCodeId: string) => {
      const res = await doFetch(
        URLS.DELETE_DISCOUNT_CODE(discountCodeId),
        'DELETE'
      );

      if (res.status !== 200) {
        throw new Error();
      }

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.discount_codes],
      });
    },
  });
};

export const useUpdateDiscountCode = () => {
  return useMutation({
    mutationFn: async (discountCode: UpdateDiscountCodeDto) => {
      const res = await doFetch(
        URLS.UPDATE_DISCOUNT_CODE(discountCode.id),
        'PUT',
        discountCode
      );

      if (res.status !== 200) {
        throw new Error();
      }
    },
  });
};
