import { Plus } from 'lucide-react';

import { useModalStore } from '../../stores/modalStore';
import { MODALS } from '../constants';
import { Button, Placeholder } from '../elements';
import { Loading } from '../partials';

import DiscountCodesGrid from './DiscountCodesGrid';
import { useDiscountCodes } from './hooks';
import AddDiscountCodeModal from './modal/AddDiscountCodeModal';

const DiscountCodesPage = () => {
  const { openModal } = useModalStore();
  const { data: discountCodes, isLoading, isError } = useDiscountCodes();

  const openAddDiscountCode = () => {
    openModal(MODALS.ADD_DISCOUNT_CODE);
  }

  return (
    <>
      <AddDiscountCodeModal />
      <main className="p-4 md:p-16 relative min-h-[calc(100vh-120px)]">
        <div className="flex-col items-start flex gap-4 md:flex-row md:items-center mb-16 md:justify-between">
          <h1 className="font-bold">Kortingscodes</h1>
          <div className="flex gap-4">
            <Button
              onClick={openAddDiscountCode}
              variant="primary"
              text="voeg kortingscode toe"
              icon={<Plus />}
            />
          </div>
        </div>

        {
          isLoading && <Loading />
        }

        {
          !isLoading && isError && (
            <div>
              Er ging iets mis tijdens het ophalen van de tutorials
            </div>
          )
        }

        {
          !isLoading && discountCodes && !isError && discountCodes.length === 0 && (
            <Placeholder
              text="Er zijn nog geen kortingscodes aangemaakt"
            />
          )
        }


        {
          !isLoading && discountCodes && !isError && (
            <DiscountCodesGrid
              data={discountCodes}
            />
          )
        }
      </main>
    </>
  );
}

export default DiscountCodesPage;
