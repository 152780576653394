import { format } from 'date-fns';
import { Cog, Info, Save, Trash } from 'lucide-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useModalStore } from '../../stores/modalStore';
import { useToastStore } from '../../stores/toastStore';
import { MODALS } from '../constants';
import { Button, Placeholder } from '../elements';
import InputError from '../elements/InputError';
import { Loading } from '../partials';

import { useDiscountCodeById, useUpdateDiscountCode, useUpdateDiscountCodeForm } from './hooks';
import DeleteDiscountCodeModal from './modal/DeleteDiscountCodeModal';

const getDateFromString = (date: string): string => {
  const inputDate = new Date(date);
  return format(inputDate, 'yyyy-MM-dd');
}

type DiscountCodesDetailsContainerProps = {
  discountCodeId: string;
};

const DiscountCodesDetailsContainer = ({
  discountCodeId,
}: DiscountCodesDetailsContainerProps) => {
  const modalStore = useModalStore();
  const { isLoading, data } = useDiscountCodeById(discountCodeId);
  const { reset, register, handleSubmit, getValues, formState: { errors } } = useUpdateDiscountCodeForm(data);
  const { mutateAsync: updateDiscountCode } = useUpdateDiscountCode();
  const { errorToast, successToast } = useToastStore();

  useEffect(() => {
    if (data !== undefined) {
      reset({
        ...data,
        expiring_at: getDateFromString(data.expiring_at)
      });
    }
  }, [reset, data]);

  const doUpdateDiscountCode = async () => {
    try {
      await updateDiscountCode(getValues());
      successToast('Kortingcode bijgewerkt');
    } catch (err) {
      errorToast('Er is iets misgegaan bij het bijwerken van de kortingscode');
    }
  };

  const displayDeleteDiscountCodeModal = () => {
    modalStore.openModal(MODALS.DELETE_DISCOUNT_CODE);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (data === undefined) {
    return <div>Er is iets misgegaan, probeer het later opnieuw</div>;
  }

  return (
    <>
      <DeleteDiscountCodeModal discountCodeId={discountCodeId} />
      <div className="flex gap-4 items-start justify-between flex-col sm:items-center sm:flex-row mb-8">
        <h1 className="font-bold">Code - {data.code}</h1>
      </div>

      <div className="bg-lighter p-4 rounded-lg">
        <div className="flex justify-start items-center mb-8 gap-4">
          <Info />
          <h2 className="text-xl font-bold">
            Kortingscode gegevens
          </h2>
        </div>

        <form onSubmit={handleSubmit(doUpdateDiscountCode)}>
          <div className="w-full mb-4">
            <label className="mb-2 block font-bold">Totaal bedrag in €</label>
            <input
              className="w-full p-4 text-text bg-primary rounded-lg"
              type="text"
              placeholder="100"
              {...register('total', {
                valueAsNumber: true
              })}
            />

            {errors?.total !== undefined && (
              <InputError text={errors.total.message} />
            )}
          </div>

          <div className="w-full mb-4">
            <label className="mb-2 block font-bold">Verloopt op</label>
            <input
              className="w-full p-4 text-text bg-primary rounded-lg"
              type="date"
              {...register('expiring_at')}
            />

            {errors?.expiring_at !== undefined && (
              <InputError text={errors.expiring_at.message} />
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label htmlFor="email" className="mb-1 font-semibold">
              Status
            </label>
            <select
              {...register('status')}
              className="p-4 bg-primary rounded-lg"
            >
              <option value="ACTIVE">Actief</option>
              <option value="EXPIRED">Verlopen</option>
              <option value="DELETED">Verwijderd</option>
            </select>
          </div>
          <Button
            type="submit"
            text="Bewaar wijzigingen"
            icon={<Save />}
            variant="primary"
            style={{ justifyContent: 'center' }}
            fullWidth
          />
        </form>
      </div>

      <div className="mt-16 bg-lighter p-4 rounded-lg">
        <div className="flex justify-start items-center mb-8 gap-4">
          <Cog />
          <h2 className="text-xl font-bold">
            Acties
          </h2>
        </div>


        <Button
          onClick={displayDeleteDiscountCodeModal}
          variant="delete"
          fullWidth
          style={{ justifyContent: 'center' }}
          text="Verwijder kortingscode"
          icon={<Trash />}
        />
      </div>

      <div className="mt-16 bg-lighter p-4 rounded-lg">
        <div className="flex justify-start items-center mb-8 gap-4">
          <Info />
          <h2 className="text-xl font-bold">
            Bestellingen waar de kortingscode is gebruikt
          </h2>
        </div>

        <Placeholder
          className="bg-primary"
          text="Er zijn nog geen bestellingen met deze kortingscode"
        />
      </div>


    </>
  );
};

const DiscountCodeDetailsPage = () => {
  const { codeId } = useParams();

  return (
    <main
      className="p-4 md:p-16"
    >
      {codeId && <DiscountCodesDetailsContainer discountCodeId={codeId} />}
    </main>
  );
};

export default DiscountCodeDetailsPage;
