import { SortRule } from '../model/SortRule';

import SortHeader from './SortHeader';

type SortRuleHeaderProps = {
  sortRules: SortRule[];
  column: string;
  updateSortOrder: (sortRule: SortRule) => void;
  headerMap?: Record<string, string>;
}

const SortRuleHeader = ({ sortRules, column, updateSortOrder, headerMap }: SortRuleHeaderProps) => {
  const sortRule = sortRules.find((rule) => rule.column === column);

  const getMappedHeader = (col: string): string => {
    if (headerMap && headerMap[col]) {
      return headerMap[col];
    }

    return col;
  }

  if (!sortRule) {
    return <span className="cursor-pointer">{getMappedHeader(column)}</span>
  }

  return (
    <div
      className="flex items-center gap-4 cursor-pointer"
      onClick={() => updateSortOrder(sortRule)}
    >
      <span>{sortRule.name}</span>
      <SortHeader sortRule={sortRule} />
    </div>
  )
}

export default SortRuleHeader;
