import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Define props for SimpleGrid with generics for column definitions and row data
interface SimpleGridProps<T> {
  columnDefs: ColDef[];
  rowData: T[];
  onRowClick?: (data: RowClickedEvent) => void;
}

// SimpleGrid component using generics
const SimpleGrid = <T,>({ columnDefs, rowData, onRowClick }: SimpleGridProps<T>) => {
  return (
    <div className="ag-theme-alpine mb-16">
      <AgGridReact
        reactiveCustomComponents
        noRowsOverlayComponent={() => <div>Er is nog geen data</div>}
        domLayout="autoHeight"
        rowClass="cursor-pointer"
        columnDefs={columnDefs}
        rowData={rowData}
        onRowClicked={(event) => onRowClick && onRowClick(event)}
        animateRows
      />
    </div>
  );
};

export default SimpleGrid;
