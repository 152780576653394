import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { QUERY_KEYS } from '../constants';
import { doFetch, uploadFileFetch } from '../utils';

import { AddCategoryDto, addCategorySchema } from './dto/add-category.dto';
import {
  Breadcrumb,
  CategoryDetailsDto,
  breadcrumbSchema,
  categoryDetailsSchema,
} from './dto/category.dto';
import {
  BaseProduct,
  Image,
  LinkedTutorial,
  Product,
  RelatedProduct,
  Variant,
  addProductSchema,
  baseProductSchema,
  imagesSchema,
  productSchema,
  updateProductSchema,
} from './dto/product.dto';
import {
  UpdateCategoryDto,
  updateCategorySchema,
} from './dto/update-category.dto';

const URLS = {
  GET_ALL_PRODUCTS: '/api/v1/products',
  ADD_CATEGORY: '/api/v1/categories',
  UPDATE_CATEGORY: (categoryId: string) => `/api/v1/categories/${categoryId}`,
  DELETE_CATEGORY: (categoryId: string) => `/api/v1/categories/${categoryId}`,
  DELETE_PRODUCT: (productId: string) => `/api/v1/products/${productId}`,
  GET_CATEGORY_BY_ID: (categoryId: string) =>
    `/api/v1/categories/${categoryId}`,
  ADD_PRODUCT: (categoryId: string) =>
    `/api/v1/categories/${categoryId}/products`,
  UPDATE_PRODUCT: (categoryId: string, productId: string) =>
    `/api/v1/categories/${categoryId}/products/${productId}`,
  GET_PRODUCT_BY_ID: (productId: string) => `/api/v1/products/${productId}`,
  UPLOAD_IMAGE: (productId: string) => `/api/v1/products/images/${productId}`,
  GET_BREADCRUMBS: (categoryId: string) =>
    `/api/v1/categories/${categoryId}/breadcrumb`,
};

const overviewStandaloneSchema = z.object({
  vat: z
    .number({
      required_error: 'Het is verlicht om het BTW % in te geven',
      invalid_type_error: 'BTW moet een nummer zijn',
    })
    .int()
    .min(0, { message: 'BTW moet minstens 0% zijn' }),

  price_inc_vat: z.number().min(0, 'Prijs moet positief zijn'),
});

// Define Zod schemas for each step
const overviewSchema = overviewStandaloneSchema.extend({
  attributes: z.object({
    color: z.string().min(1, 'Kleur is verplicht'),
    width: z.string().min(1, 'Breedte is verplicht'),
    finish: z.string().min(1, 'Afwerking is verplicht'),
    height: z.string().min(1, 'Hoogte is verplicht'),
    depth: z.string().min(1, 'Diepte is verplicht'),
  }),
});

const stockSchema = z.object({
  display_in_webshop: z.boolean(),
  favourite: z.boolean(),
  delivery_time: z.string().min(1, 'Levertermijn is verplicht'),
  stock: z.number().min(0, 'Stock moet positief zijn'),
  lower_threshold_warning: z.number().min(0, 'Waarschuwing moet positief zijn'),
});

const imageSchema = z.object({
  image_to_display: z.string().min(1, 'Selecteer een afbeelding'),
  image_src: z.string().optional(),
  // Add other fields and their validations here
});

export enum VIEWS {
  OVERVIEW = 'OVERVIEW',
  STOCK = 'STOCK',
  IMAGE = 'IMAGE',
}
// Map schemas to views
export const schemas = {
  'OVERVIEW-TABLE': overviewSchema,
  'OVERVIEW-STANDALONE': overviewStandaloneSchema,
  STOCK: stockSchema,
  IMAGE: imageSchema,
};

type SchemaKeys = keyof typeof schemas;

// Update the hook to accept activeView
export const useVariantForm = (
  activeView: VIEWS,
  variant?: Variant | null,
  productType?: string
) => {
  const defaultVariant: Variant = {
    image_to_display: '',
    id: '',
    price_inc_vat: 0,
    vat: 21,
    stock: 0,
    notes: '',
    delivery_time: '',
    display_in_webshop: true,
    favourite: false,
    lower_threshold_warning: null,
    attributes: {
      color: '#000000',
      width: '60cm',
      finish: 'Zelf af te werken',
      height: '90cm',
      depth: '30cm',
    },
  };

  let schemaKey: SchemaKeys | undefined = undefined;

  switch (activeView) {
    case VIEWS.OVERVIEW:
      schemaKey = productType
        ? (`${activeView}-${productType}` as SchemaKeys)
        : (activeView as SchemaKeys);
      break;
    case VIEWS.STOCK:
    case VIEWS.IMAGE:
      schemaKey = activeView as SchemaKeys;
      break;
  }

  // Get the current schema based on activeView
  const currentSchema = schemas[schemaKey] ?? z.object({});

  // Use the schema for validation
  return useForm<Variant>({
    resolver: zodResolver(currentSchema),
    defaultValues: variant || defaultVariant,
  });
};

export const useEditCategoryForm = (category: UpdateCategoryDto) => {
  return useForm<UpdateCategoryDto>({
    defaultValues: category,
    resolver: zodResolver(updateCategorySchema),
  });
};

export const useCategoryForm = (category: AddCategoryDto) => {
  return useForm<AddCategoryDto>({
    defaultValues: category,
    resolver: zodResolver(addCategorySchema),
  });
};

export const useProductForm = (product?: Partial<Product>) => {
  const defaultProductDto: Product = {
    id: uuidv4(),
    name: '',
    description: '',
    type: 'TABLE',
    category_id: '',
    notes: '',
    variants: [],
    images: [],
    related_products: [],
    linked_tutorials: [],
  };

  const schema = z.object({
    name: z.string().min(2, 'Vul een naam in voor het nieuwe product'),
    description: z.string().min(2, 'Vul een beschrijving in voor het product'),
  });

  const formProps = useForm<Product>({
    defaultValues: product || defaultProductDto,
    resolver: zodResolver(schema),
  });

  formProps.watch([
    'name',
    'type',
    'images',
    'linked_tutorials',
    'related_products',
  ]);

  // Use useFieldArray to manage variants
  const {
    fields: variantFields,
    append: appendVariant,
    remove: handleRemoveVariant,
    update: handleUpdateVariant,
  } = useFieldArray({
    control: formProps.control,
    name: 'variants', // Name of the field. Must match the key in your form values
  });

  // Function to add a new variant
  const addVariant = (variant: Variant) => {
    appendVariant(variant);
  };

  // Function to remove a variant
  const removeVariant = (variant: Variant) => {
    const variants = formProps.getValues()?.variants || [];

    const index = variants.findIndex(({ id }) => id === variant.id);
    if (index > -1) {
      handleRemoveVariant(index);
    }
  };

  // Function to update a variant
  const updateVariant = (variant: Variant) => {
    const variants = formProps.getValues()?.variants || [];

    const index = variants.findIndex(({ id }) => id === variant.id);
    if (index > -1) {
      handleUpdateVariant(index, variant);
    }
  };

  // Managing linked_tutorials
  const {
    fields: linkedTutorialFields,
    append: appendLinkedTutorial,
    remove: handleRemoveLinkedTutorial,
    update: handleUpdateLinkedTutorial,
  } = useFieldArray({
    control: formProps.control,
    name: 'linked_tutorials', // Name of the field. Must match the key in your form values
  });

  // Function to add a new linked tutorial
  const addLinkedTutorial = (linkedTutorial: LinkedTutorial) => {
    appendLinkedTutorial(linkedTutorial);
  };

  // Function to remove a linked tutorial
  const removeLinkedTutorial = (linkedTutorial: LinkedTutorial) => {
    const linkedTutorials = formProps.getValues()?.linked_tutorials || [];

    const index = linkedTutorials.findIndex(
      ({ id }) => id === linkedTutorial.id
    );
    if (index > -1) {
      handleRemoveLinkedTutorial(index);
    }
  };

  // Function to update a linked tutorial
  const updateLinkedTutorial = (linkedTutorial: LinkedTutorial) => {
    const linkedTutorials = formProps.getValues()?.linked_tutorials || [];

    const index = linkedTutorials.findIndex(
      ({ id }) => id === linkedTutorial.id
    );
    if (index > -1) {
      handleUpdateLinkedTutorial(index, linkedTutorial);
    }
  };

  // Managing images
  const {
    fields: imageFields,
    append: appendImage,
    remove: handleRemoveImage,
  } = useFieldArray({
    control: formProps.control,
    name: 'images', // Name of the field. Must match the key in your form values
  });

  // Function to add a new image
  const addImage = (image: Image) => {
    appendImage(image);
  };

  // Function to remove an image
  const removeImage = (imagePath: string) => {
    const images = formProps.getValues()?.images || [];

    const index = images.findIndex(({ path }) => path === imagePath);

    if (index > -1) {
      handleRemoveImage(index);
    }
  };

  // Managing related_products
  const {
    fields: relatedProductFields,
    append: appendRelatedProduct,
    remove: handleRemoveRelatedProduct,
    update: handleUpdateRelatedProduct,
  } = useFieldArray({
    control: formProps.control,
    name: 'related_products', // Name of the field. Must match the key in your form values
  });

  // Function to add a new related product
  const addRelatedProduct = (relatedProduct: RelatedProduct) => {
    appendRelatedProduct(relatedProduct);
  };

  // Function to remove a related product
  const removeRelatedProduct = (relatedProduct: RelatedProduct) => {
    const relatedProducts = formProps.getValues()?.related_products || [];

    const index = relatedProducts.findIndex(
      ({ id }) => id === relatedProduct.id
    );
    if (index > -1) {
      handleRemoveRelatedProduct(index);
    }
  };

  // Function to update a related product
  const updateRelatedProduct = (relatedProduct: RelatedProduct) => {
    const relatedProducts = formProps.getValues()?.related_products || [];

    const index = relatedProducts.findIndex(
      ({ id }) => id === relatedProduct.id
    );
    if (index > -1) {
      handleUpdateRelatedProduct(index, relatedProduct);
    }
  };

  return {
    ...formProps,
    fields: variantFields,
    addVariant,
    removeVariant,
    updateVariant,
    linkedTutorialFields,
    addLinkedTutorial,
    removeLinkedTutorial,
    updateLinkedTutorial,
    imageFields,
    addImage,
    removeImage,
    relatedProductFields,
    addRelatedProduct,
    removeRelatedProduct,
    updateRelatedProduct,
  };
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (productId: string) => {
      const { status, data } = await doFetch(
        URLS.DELETE_PRODUCT(productId),
        'DELETE',
        {}
      );

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const deleteProduct = async (productId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutate(productId, {
        onSuccess: (productId: string) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.products, productId],
          });
          resolve();
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    deleteProduct,
    error,
  };
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (categoryId: string) => {
      const { status, data } = await doFetch(
        URLS.DELETE_CATEGORY(categoryId),
        'DELETE',
        {}
      );

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const deleteCategory = async (categoryId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutate(categoryId, {
        onSuccess: (categoryId: string) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.categories, categoryId],
          });
          resolve();
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    deleteCategory,
    error,
  };
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (product: Product) => {
      const { category_id, id, type } = product;
      const parsedData = updateProductSchema.parse(product);

      if (type === 'STANDALONE') {
        parsedData.variants.forEach((variant: Variant) => {
          variant.attributes = {};
        });
      }

      const { status, data } = await doFetch(
        URLS.UPDATE_PRODUCT(category_id ?? '', id),
        'PUT',
        parsedData
      );

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const updateProduct = async (product: Product): Promise<Product> => {
    return new Promise((resolve, reject) => {
      mutate(product, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.categories, product.category_id],
          });
          resolve(product);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    updateProduct,
    error,
  };
};

export const useAddProduct = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (product: Product) => {
      const { category_id, type } = product;
      const nilUUID = '00000000-0000-0000-0000-000000000000';
      const parsedData = addProductSchema.parse(product);

      if (type === 'STANDALONE') {
        parsedData.variants.forEach((variant: Variant) => {
          variant.attributes = {};
        });
      }

      const { status, data } = await doFetch(
        URLS.ADD_PRODUCT(category_id || nilUUID),
        'POST',
        parsedData
      );

      if (status !== 201) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const addProduct = async (productDto: Product): Promise<Product> => {
    return new Promise((resolve, reject) => {
      mutate(productDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.categories],
          });
          resolve(productDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    addProduct,
    error,
  };
};

export const useEditCategory = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (category: UpdateCategoryDto) => {
      const { name, description, parentId, id, imageSrc } = category;

      const { status, data } = await doFetch(URLS.UPDATE_CATEGORY(id), 'PUT', {
        name,
        description,
        parentId,
        image: imageSrc?.replace(
          /data:image\/(?:jpeg|jpg|png|gif|bmp|webp|svg\+xml);base64,/,
          ''
        ),
      });

      if (status !== 200) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const editCategory = async (
    editCategoryDto: UpdateCategoryDto
  ): Promise<UpdateCategoryDto> => {
    return new Promise((resolve, reject) => {
      mutate(editCategoryDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.categories, editCategoryDto.id],
          });
          resolve(editCategoryDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    editCategory,
    error,
  };
};

export const useAddCategory = () => {
  const queryClient = useQueryClient();
  const { isPending, isError, mutate, error } = useMutation({
    mutationFn: async (addCategoryDto: AddCategoryDto) => {
      const { name, description, parentId, imageSrc } = addCategoryDto;

      const { status, data } = await doFetch(URLS.ADD_CATEGORY, 'POST', {
        name,
        description,
        parentId,
        image: imageSrc?.replace(
          /data:image\/(?:jpeg|jpg|png|gif|bmp|webp|svg\+xml);base64,/,
          ''
        ),
      });

      if (status !== 201) {
        throw new Error(data.message);
      }

      return data;
    },
  });

  const addCategory = async (
    addCategoryDto: AddCategoryDto
  ): Promise<AddCategoryDto> => {
    return new Promise((resolve, reject) => {
      mutate(addCategoryDto, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.categories],
          });
          resolve(addCategoryDto);
        },
        onError: reject,
      });
    });
  };

  return {
    isPending,
    isError,
    addCategory,
    error,
  };
};

export const useProductById = (productId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.products, productId],
    queryFn: async () => {
      const { status, data } = await doFetch(
        URLS.GET_PRODUCT_BY_ID(productId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return productSchema.parse(data);
    },
  });
};

export const useProducts = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.products],
    queryFn: async (): Promise<undefined | BaseProduct[]> => {
      const { status, data } = await doFetch(URLS.GET_ALL_PRODUCTS, 'GET');

      if (status !== 200) {
        throw new Error();
      }

      return z.array(baseProductSchema).parse(data);
    },
  });
};

export const useBreadCrumbs = (categoryId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.breadcrumbs, categoryId],
    queryFn: async (): Promise<undefined | Breadcrumb> => {
      const { status, data } = await doFetch(
        URLS.GET_BREADCRUMBS(categoryId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return breadcrumbSchema.parse(data);
    },
  });
};

export const useCategoryById = (categoryId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.categories, categoryId],
    queryFn: async (): Promise<undefined | CategoryDetailsDto> => {
      const { status, data } = await doFetch(
        URLS.GET_CATEGORY_BY_ID(categoryId),
        'GET'
      );

      if (status !== 200) {
        throw new Error();
      }

      return categoryDetailsSchema.parse(data);
    },
  });
};

export const useUploadImages = () => {
  const { isPending, isError, mutateAsync, error } = useMutation({
    mutationFn: async ({
      productId,
      files,
    }: {
      productId: string;
      files: File[];
    }) => {
      const url = URLS.UPLOAD_IMAGE(productId);
      const { status, data } = await uploadFileFetch(url, 'POST', files);

      if (status !== 201) {
        throw new Error('An error occurred while uploading images');
      }

      // queryClient.invalidateQueries({
      //   queryKey: [QUERY_KEYS.products, productId],
      // });
      const parsedData = imagesSchema.parse(data);
      return { status, data: parsedData };
    },
  });

  return {
    isPending,
    isError,
    uploadImages: mutateAsync,
    error,
  };
};
